import { Grid, makeStyles } from "@material-ui/core"
import { red } from "@material-ui/core/colors"


const LanguageMenu = ({language, onChangeLanguage}) => {

    const classes = useLanguageStyles()

    return(
        <div style={{paddingLeft:16}}>
            <Grid container >
                <Grid item>
                    <div className={classes.root} onClick={()=>onChangeLanguage('es')} style={{borderColor:language === 'es' ? red[400] : 'transparent'}}>
                        <div className={classes.text_container} >
                            ES
                        </div>
                    </div>
                </Grid>
                <Grid item>
                    <div className={classes.root} onClick={()=>onChangeLanguage('en')} style={{borderColor:language === 'en' ?'#184EA2' : 'transparent'}}>
                        <div className={classes.text_container} >
                            EN
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default LanguageMenu

const useLanguageStyles = makeStyles(theme => ({
    root:{
        position:'relative',
        width:36,
        height:36,
        //marginLeft:16,
        marginLeft:8,
        color:'white',
        //paddingLeft:36,
        cursor:'pointer',
        border:'1px solid white',
        borderRadius:'50%'
    },
    text_container:{
        position:'absolute', top:'50%', left:'50%',
        transform:'translate(-50%, -50%)'
    },
    paper:{
        background:'transparent',
        color:'white',
        marginTop:16
    },
    menu_item:{

        padding:'12px 0px',
        paddingLeft:12,
        textAlign:'flex-end',
        cursor:'pointer'
    }

}))